import React from "react";

export type CardButtonProps = {
  image?: string;
  icon?: string;
  title: string;
  value?: string;
  selected?: boolean;
  onClick?: (value: string) => void;
};

function CardButton({
  image,
  title,
  value,
  onClick,
  icon,
  selected = false,
}: CardButtonProps) {
  return (
    <button
      className={`card-btn ${!image && !icon ? "text-only" : ""} ${
        selected ? "selected" : ""
      }`}
      onClick={() => {
        onClick && onClick(value ?? title);
      }}
    >
      {image && <img src={image} alt={title} />}
      {icon && (
        <div className="icon">
          <i className={`fal fa-${icon}`}></i>
        </div>
      )}
      <div className="title">{title}</div>
    </button>
  );
}

export default CardButton;
