import React, { useState } from "react";
import axios from "axios";
import "./App.css";
import Button from "./Button";
import CardSelect from "./CardSelect";
import Footer from "./Footer";
import Input from "./Input";
import LocationInput from "./LocationInput";
import Panel from "./Panel";
import Row from "./Row";
import Steps from "./Steps";

const typeOptions = [
  {
    icon: "building",
    title: "Wohnung",
  },
  {
    icon: "home",
    title: "Haus",
  },
  {
    icon: "hotel",
    title: "Mehrfamilienhaus",
  },
];

const houseTypeOptions = [
  { title: "Frei stehend" },
  { title: "Doppelhaushälfte" },
  { title: "Reihenmittelhaus" },
  { title: "Reihenendhaus" },
];

const flatTypeOptions = [
  { title: "Erdgeschoss" },
  { title: "Etagenwohnung" },
  { title: "Dachgeschoss" },
  { title: "Penthouse" },
];

const contactOptions = [
  { title: "Vormittags" },
  { title: "Nachmittags" },
  { title: "Abends" },
];

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function App() {
  const [step, setStep] = useState(0);
  const [address, setAddress] = useState<any>(null);
  const [type, setType] = useState<string>("");
  const [subType, setSubType] = useState<string>("");
  const [infos, setInfos] = useState<any>({});
  const [contact, setContact] = useState<any>({});

  return (
    <div className="bl-valuation">
      <div id="marker-top">&nbsp;</div>
      {step === 0 && (
        <Panel title="Lassen Sie Ihre Immobilie bewerten">
          <LocationInput
            apiKey="AIzaSyD_xwgBqRmYHtrcW7TaIX4Ua__oH23UgoQ"
            value={address}
            onChange={setAddress}
          />
          {address && address.postcode && address.number && (
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <Button
                onClick={() => {
                  setStep(1);
                  document.getElementById("marker-top")?.scrollIntoView();
                }}
              >
                Jetzt bewerten
              </Button>
            </div>
          )}
        </Panel>
      )}
      {step === 1 && (
        <Panel title="Wählen Sie die Art ihrer Immobilie">
          <CardSelect
            options={typeOptions}
            value={type}
            onClick={(selected) => {
              setType(selected);
              document.getElementById("marker")?.scrollIntoView();
            }}
          />
          <div id="marker">&nbsp;</div>
          {type && type !== "Wohnung" && (
            <CardSelect
              options={houseTypeOptions}
              value={subType}
              onClick={(selected) => {
                setSubType(selected);
                setStep(2);
                document.getElementById("marker-top")?.scrollIntoView();
              }}
            />
          )}
          {type && type === "Wohnung" && (
            <CardSelect
              options={flatTypeOptions}
              value={subType}
              onClick={(selected) => {
                setSubType(selected);
                setStep(2);
                document.getElementById("marker-top")?.scrollIntoView();
              }}
            />
          )}
        </Panel>
      )}
      {step === 2 && (
        <Panel title="Wir benötigen noch folgende Angaben">
          <Input
            placeholder="Baujahr"
            numeric
            value={infos.constructionYear ?? ""}
            onChange={(val) => setInfos({ ...infos, constructionYear: val })}
          />
          <Input
            placeholder="Wohnfläche"
            value={infos.livingArea ?? ""}
            numeric
            onChange={(val) => setInfos({ ...infos, livingArea: val })}
            addon={
              <span>
                m<sup>2</sup>
              </span>
            }
          />
          {type !== "Wohnung" && (
            <Input
              placeholder="Grundstücksfläche"
              numeric
              value={infos.ground ?? ""}
              onChange={(val) => setInfos({ ...infos, ground: val })}
              addon={
                <span>
                  m<sup>2</sup>
                </span>
              }
            />
          )}
          {infos.constructionYear &&
            infos.livingArea &&
            (type === "Wohnung" || infos.ground) && (
              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <Button
                  onClick={() => {
                    setStep(3);
                    document.getElementById("marker-top")?.scrollIntoView();
                  }}
                >
                  Bewertung berechnen
                </Button>
              </div>
            )}
        </Panel>
      )}

      {step === 3 && (
        <Panel
          title="Wie können wir Sie erreichen?"
          intro="Da jede Immobilie ihre Besonderheiten hat, können wir nur in einem
        persönlichen Gespräch zuverlässig den genauen Marktwert ermitteln.
        Bitte geben Sie eine gültige Telefonnummer an, damit wir Sie
        erreichen können."
        >
          <Row>
            <Input
              placeholder="Vorname"
              value={contact.firstName ?? ""}
              onChange={(val) => setContact({ ...contact, firstName: val })}
            />
            <Input
              placeholder="Nachname"
              value={contact.lastName ?? ""}
              onChange={(val) => setContact({ ...contact, lastName: val })}
            />
          </Row>
          <Row>
            <Input
              type="email"
              placeholder="Email"
              value={contact.email ?? ""}
              onChange={(val) => setContact({ ...contact, email: val })}
            />
            <Input
              placeholder="Telefon (optional)"
              value={contact.phone ?? ""}
              onChange={(val) => setContact({ ...contact, phone: val })}
            />
          </Row>

          {contact.phone && (
            <CardSelect
              options={contactOptions}
              value={contact.time}
              onClick={(selected) => {
                setContact({ ...contact, time: selected });
              }}
            />
          )}

          {contact.firstName &&
            contact.lastName &&
            contact.email &&
            validateEmail(contact.email) &&
            (!contact.phone || contact.time) && (
              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <Button
                  onClick={async () => {
                    const data = {
                      address,
                      type,
                      subType,
                      infos,
                      contact,
                    };

                    console.log(data);

                    try {
                      const response = await axios.post(
                        "https://www.bl-immobilien.net/index.php?option=com_immo&task=valuation.request",
                        data
                      );
                      console.log(response.data);

                      setStep(4);
                      document.getElementById("marker-top")?.scrollIntoView();
                    } catch (err) {
                      setStep(5);
                      document.getElementById("marker-top")?.scrollIntoView();
                    }
                  }}
                >
                  Jetzt anfordern
                </Button>
              </div>
            )}
        </Panel>
      )}

      {step < 4 && (
        <Footer>
          <Steps count={4} value={step} onChange={setStep} />
        </Footer>
      )}

      {step === 4 && (
        <div style={{ textAlign: "center" }}>
          <h2>Vielen Dank für Ihre Anfrage!</h2>
          <h3>Wir werden uns in Kürze mit Ihnen in Verbindung setzen.</h3>
        </div>
      )}

      {step === 5 && (
        <div style={{ textAlign: "center" }}>
          <h2>Leider ist ein Fehler aufgetreten!</h2>
          <h3>Bitte kontaktieren Sie uns direkt per Telefon oder Mail!</h3>
          <Button
            onClick={() => {
              setStep(3);
            }}
          >
            Erneut versuchen
          </Button>
        </div>
      )}
    </div>
  );
}

export default App;
