import React from "react";

export type InputProps = {
  placeholder?: string;
  addon?: React.ReactNode;
  value?: string;
  onChange?: (value: string) => void;
  numeric?: boolean;
  type?: string;
};

function Input({
  placeholder,
  addon,
  value,
  onChange,
  numeric = false,
  type = "text",
}: InputProps) {
  return (
    <div className="input-group">
      <div className={`form-control ${addon ? "has-addon" : ""}`}>
        <input
          type={type}
          pattern={numeric ? "[0-9]*" : undefined}
          inputMode={numeric ? "numeric" : undefined}
          placeholder={placeholder}
          value={value}
          onChange={(event) =>
            onChange &&
            onChange(
              numeric
                ? event.target.value.replace(/\D/g, "")
                : event.target.value
            )
          }
        />
        {addon && <div className="addon">{addon}</div>}
      </div>
    </div>
  );
}

export default Input;
