import React from "react";
import CardButton, { CardButtonProps } from "./CardButton";
import Row from "./Row";

export type CardSelectOption = Omit<CardButtonProps, "onClick">;

export type CardSelectProps = {
  options: CardSelectOption[];
  value: string;
  onClick: (value: string) => void;
};

function CardSelect({ options, value, onClick }: CardSelectProps) {
  return (
    <Row>
      {options.map((opt) => {
        return (
          <CardButton
            key={opt.title}
            {...opt}
            selected={value === (opt.value ?? opt.title)}
            onClick={onClick}
          />
        );
      })}
    </Row>
  );
}

export default CardSelect;
