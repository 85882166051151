import React from "react";

export type StepsProps = {
  count?: number;
  value?: number;
  onChange?: (step: number) => void;
};

function Steps({ count = 1, value = 0, onChange = () => {} }: StepsProps) {
  return (
    <div className="steps">
      {[...Array(count)].map((e, i) => {
        return (
          <div
            className={`step ${i === value ? "current" : ""} ${
              i < value ? "" : "disabled"
            }`}
            key={i}
            onClick={() => i < value && onChange(i)}
          ></div>
        );
      })}
    </div>
  );
}

export default Steps;
