import React from "react";

export type FooterProps = {
  children: React.ReactNode;
};

function Footer({ children }: FooterProps) {
  return <div className="bl-val-footer">{children}</div>;
}

export default Footer;
