import React from "react";

export type PanelProps = {
  children: React.ReactNode;
  title?: string;
  intro?: string;
};

function Panel({ children, title, intro }: PanelProps) {
  return (
    <div className="panel">
      {title && <h2>{title}</h2>}
      {intro && <p style={{ textAlign: "center" }}>{intro}</p>}
      <div className="panel-content">{children}</div>
    </div>
  );
}

export default Panel;
