// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";

let autoComplete;

const loadScript = (url: string, callback) => {
  if (window.google?.maps?.places) {
    callback();
    return;
  }
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["address"], componentRestrictions: { country: "de" } }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(callback) {
  const addressObject = autoComplete.getPlace();
  if (!addressObject.address_components) {
    return;
  }
  callback(addressObject);
}

function LocationInput({ apiKey, onChange, value }) {
  const [query, setQuery] = useState(
    value
      ? `${value.street} ${value.number ?? ""}, ${value.postcode} ${value.city}`
      : ""
  );
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
      () =>
        handleScriptLoad((addressObject) => {
          if (!addressObject) {
            onChange && onChange(null);
          }

          setQuery(addressObject.formatted_address ?? "");

          const street = addressObject.address_components.find((c) =>
            c.types.includes("route")
          )?.long_name;
          const number = addressObject.address_components.find((c) =>
            c.types.includes("street_number")
          )?.long_name;
          const city = addressObject.address_components.find((c) =>
            c.types.includes("locality")
          )?.long_name;
          const postcode = addressObject.address_components.find((c) =>
            c.types.includes("postal_code")
          )?.long_name;

          onChange &&
            onChange({
              street,
              number,
              hasNumber: !!number,
              city,
              postcode,
              hasPostcode: !!postcode,
            });
        }, autoCompleteRef)
    );
  }, [apiKey, onChange]);

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        onChange={(event) => {
          setQuery(event.target.value);
          onChange && onChange(null);
        }}
        placeholder="Straße, Hausnummer, Ort"
        value={query}
      />
      {value && !value.hasNumber && (
        <input
          onChange={(event) =>
            onChange({ ...value, number: event.target.value })
          }
          placeholder="Hausnummer"
          value={value.number ?? ""}
        />
      )}
      {value && !value.hasPostcode && (
        <input
          onChange={(event) =>
            onChange({ ...value, postcode: event.target.value })
          }
          placeholder="PLZ"
          value={value.postcode ?? ""}
        />
      )}
    </div>
  );
}

export default LocationInput;
