import React from "react";

export type RowProps = {
  children: React.ReactNode;
};

function Row({ children }: RowProps) {
  return <div className="bl-val-row">{children}</div>;
}

export default Row;
