import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

const target = document.getElementById("bl-val");

if (target) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    target
  );
}
